<template>
    <pagination :title="title" :entities="users" :filterAction="filterAction" headerColourClass="bg-transparent">
        <template v-slot:title>
            <div class="flex justify-between items-center">
                <span>{{ title }}</span>
            </div>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Date
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Actual In</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Actual Out</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Billable In</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Time Billable Total (h)</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Billable Out</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Location In</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Location Out</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Photo In</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Photo Out</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Comments</th>
        </template>
        <template v-slot:items>
            <tr v-for="user in users">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    {{user.entity.properties.get('date')}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <form-action v-if="user.entity.actions.get('update-actual-in') &&
                                    formatToTime(user.entity.properties.get('time_actual_in')) !== ''"
                                 :action="user.entity.actions.get('update-actual-in')"
                                 actionKey="update-actual-in"
                                 :onSuccessPath="$route.fullPath"
                                :isButton="false"
                                :title="formatToTime(user.entity.properties.get('time_actual_in'))">
                    </form-action>
                    <template v-else>
                        {{formatToTime(user.entity.properties.get('time_actual_in'))}}
                    </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <form-action v-if="user.entity.actions.get('update-actual-out')"
                                 :action="user.entity.actions.get('update-actual-out')"
                                 actionKey="update-actual-out"
                                 :onSuccessPath="$route.fullPath"
                                 :isButton="false"
                                 :title="user.entity.properties.get('time_actual_out')? formatToTime(user.entity.properties.get('time_actual_out')) : 'N/A'">
                    </form-action>
                    <template v-else>
                        {{formatToTime(user.entity.properties.get('time_actual_out'))}}
                    </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{formatToTime(user.entity.properties.get('time_billable_in'))}}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <template v-if="user.entity.properties.get('time_billable_out')">
                    {{formatToTime(user.entity.properties.get('time_billable_out'))}}
                    </template>
                    <template v-else>N/A</template>
                </td>

                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    {{user.entity.properties.get('time_billable_hours_total')}}
                </td>


                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <location-viewer-with-icon v-if="user.entity.properties.get('location_in') !== 'N/A' && user.entity.properties.get('location_in')['x'] !== null && user.entity.properties.get('location_in')['y'] !==null" :x="user.entity.properties.get('location_in')['x']" :y="user.entity.properties.get('location_in')['y']"></location-viewer-with-icon>
                    <form-action v-if="user.entity.actions.get('update-project-0')"
                                      :action="user.entity.actions.get('update-project-0')"
                                      actionKey="update-project-0"
                                      :onSuccessPath="$route.fullPath"
                                      :title="user.entity.properties.get('location_in')['project']"
                                      :isButton="false">
                    </form-action>
                  <template v-else>
                    <div>
                      {{user.entity.properties.get('location_in')['project']}}
                    </div>
                  </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <location-viewer-with-icon v-if="user.entity.properties.get('location_out') !== 'N/A' && user.entity.properties.get('location_out')['x'] !== null && user.entity.properties.get('location_out')['y'] !==null" :x="user.entity.properties.get('location_out')['x']" :y="user.entity.properties.get('location_out')['y']"></location-viewer-with-icon>
                    <form-action v-if="user.entity.actions.get('update-project-1')"
                                      :action="user.entity.actions.get('update-project-1')"
                                      actionKey="update-project-1"
                                      :onSuccessPath="$route.fullPath"
                                      :title="user.entity.properties.get('location_out')['project']"
                                      :isButton="false">
                    </form-action>
                  <template v-else>
                    <div>
                      {{user.entity.properties.get('location_out')['project']}}
                    </div>
                  </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <photo-viewer-with-icon v-if="user.entity.properties.get('photo_in') !== 'N/A'" :file-name="user.entity.properties.get('photo_in')"></photo-viewer-with-icon>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                    <photo-viewer-with-icon v-if="user.entity.properties.get('photo_out') !== 'N/A'" :file-name="user.entity.properties.get('photo_out')"></photo-viewer-with-icon>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    <template v-if="user.entity.properties.get('comment_in')">
                        clock in: {{user.entity.properties.get('comment_in')}}<br>
                    </template>
                    <template v-if="user.entity.properties.get('comment_out')">
                        clock out: {{user.entity.properties.get('comment_out')}}<br>
                    </template>
                    {{user.entity.properties.get('comment')}}
                    <form-action v-if="user.entity.actions.get('comment')"
                                 :action="user.entity.actions.get('comment')"
                                 actionKey="comment"
                                 :onSuccessPath="$route.fullPath">
                    </form-action>
                </td>
            </tr>

            <tr>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-right" :colspan="5">Weekly Total (h):</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">{{getWeeklyHoursTotal()}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm" :colspan="6"></td>
            </tr>

        </template>
        <template v-slot:no-items>
            <template v-if="filterAction.fields.filter(field => field.name === 'user_id').first().value === null">
                Please select an employee to view their timesheet.
            </template>
            <template v-else>
                No timesheet editor found
            </template>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import dayjs from "dayjs";
import LocationViewerWithIcon from "@/components/LocationViewerWithIcon.vue";
import PhotoViewerWithIcon from "@/components/PhotoViewerWithIcon.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";

export default{
  name: 'TimesheetEditorPagination',
    components: {FormAction, PhotoViewerWithIcon, LocationViewerWithIcon, Pagination},
  props: {
      users: {},
      parent:{
          type: Object,
          default: null
      },
      links:{
          type: Object,
          default:null
    },
      title:{
        type: String,
          default: 'Timesheet Editor'
      }
  },
    computed:{
        filterAction(){
            if(!this.parent){
                return null;
            }
            return this.parent.actions.filter(action => action.name === 'show-timesheet').first();
        },
    },
    methods:{
        formatToTime(datetime){
            return dayjs(datetime).format('HH:mm');
        },
        hasLink(links,key){
            if(links) {
                return links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        isActive(key){
            return this.$route.path.includes(key)
        },
        getWeeklyHoursTotal(){
            return this.parent.properties.get('hours_total')
        },
        getBillableTotal(){
            return this.parent.properties.get('billable_total')
        }
    }
}
</script>
